import React from "react";
import RatingTable from "./rating-table"
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function HomePage() {
  return (
    <AppMainLayout>
      <div className="row">

      {/* <div className="col-md-12">
       
        </div> */}

        <div className="col-md-12">
          <RatingTable />
        </div>
        {/* <div className="col-md-4">
         интервью
        </div> */}
      </div>
    </AppMainLayout>
  );
}


export default HomePage