import React, { useState, forwardRef, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import { PencilIcon } from "../../../../svg-icons/icon-pencil";
import { TrashIcon } from "../../../../svg-icons/icon-trash";
import { ADD_PLAYER, GET_PLAYERS, EDIT_PLAYER, DELETE_PLAYER } from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CookieHelper from "../../../../helpers/cookie-helper";
import { EyeIcon } from "../../../../helpers/icons/icon-eye"
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

import "./styles.scss"


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Players(props) {

  const [open, setOpen] = useState(false);
  const [players, setPlayers] = useState([]);
  const [fetchPlayers, setFetchPlayers] = useState(false)
  const [fetchDeletePlayer, setFetchDeletePlayer] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [playerName, setPlayerName] = useState("");
  const [playerSurname, setPlayerSurname] = useState("");
  const [playerRating, setPlayerRating] = useState("");
  const [playerForehand, setPlayerForehand] = useState("");
  const [playerBackhand, setPlayerBackhand] = useState("");
  const [playerHand, setPlayerHand] = useState("");
  const [playerHvat, setPlayerHvat] = useState("");
  const [playerBlade, setPlayerBlade] = useState("");
  const [playerCoach, setPlayerCoach] = useState("");
  const [editData, setEditData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [tcaToken, setTcaToken] = useState("");

  useEffect(() => {
    const isToken = CookieHelper("get", "tca-token");
    setTcaToken(isToken)
  }, [])

  useEffect(() => {
    setIsFetching(true)
    axiosFetch(GET_PLAYERS).then(res => {
      setPlayers(res)
      setIsFetching(false)
    })

  }, [fetchPlayers])

  const addPlayer = () => {
    const formData = new FormData()
    formData.append("name", playerName)
    formData.append("surname", playerSurname)
    formData.append("rating", playerRating)

    formData.append("forehand", playerForehand)
    formData.append("backhand", playerBackhand)
    formData.append("blade", playerBlade)
    formData.append("coach", playerCoach)
    formData.append("hvat", playerHvat)
    formData.append("hand", playerHand)

    axiosFetch(ADD_PLAYER, formData).then(res => {
      if (res === "OK") {
        setFetchPlayers(!fetchPlayers)
        setPlayerName("");
        setPlayerSurname("");
        handleClose()
      }
    })
  }

  const editPlayer = () => {
    const formData = new FormData()
    formData.append("name", editData.name)
    formData.append("surname", editData.surname)
    formData.append("rating", editData.rating)
    formData.append("id", editData.id)

    formData.append("forehand", editData.forehand)
    formData.append("backhand", editData.backhand)
    formData.append("blade", editData.blade)
    formData.append("coach", editData.coach)
    formData.append("hvat", editData.hvat)
    formData.append("hand", editData.hand)

    axiosFetch(EDIT_PLAYER, formData).then(res => {
      if (res === "OK") {
        setFetchPlayers(!fetchPlayers)
        setPlayerName("");
        setPlayerSurname("");
        handleClose()
      }
    })
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setEditData({})
    setPlayerRating("")
  };

  const onCellClick = (row) => {
    setEditData(row);
    handleClickOpen()
  };

  const setEditPlayer = (state, value) => {

    if (state === "rating") {
      const checkNumbers = /^\d+(\.\d)?\d*$/
      const isValid = checkNumbers.test(value)

      if (value === "" || isValid) {
        setEditData({
          ...editData,
          [state]: value
        })
      }
    } else {
      setEditData({
        ...editData,
        [state]: value
      })
    }

  }

  const cancelEditMode = () => {
    setEditData({})
    setShowDeleteModal(false)
  }

  const checkPlayerRating = (value) => {

    const checkNumbers = /^\d*\.?\d*$/
    const isValid = checkNumbers.test(value)

    if (value === "" || isValid) {
      setPlayerRating(value)
    }
  }

  const renderModal = () => {
    const modalValueName = editData?.id ? editData.name : playerName
    const modalValueSurname = editData?.id ? editData.surname : playerSurname
    const modalRating = editData?.id ? editData.rating : playerRating
    const modalValueForehand = editData?.id ? editData.forehand : playerForehand
    const modalValueBackHand = editData?.id ? editData.backhand : playerBackhand
    const modalValueBlade = editData?.id ? editData.blade : playerBlade
    const modalValueHand = editData?.id ? editData.hand : playerHand
    const modalValueHvat = editData?.id ? editData.hvat : playerHvat
    const modalValueCoach = editData?.id ? editData.coach : playerCoach
    const btnLabel = editData?.id ? "Редактировать" : "Добавить"

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"

      >
        <div className="add-event-container" style={{ minWidth: 500 }}>
          <DialogTitle>Добавление игрока</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic-1"
              label="Фамилия"
              fullWidth
              variant="outlined"
              value={modalValueName}
              onChange={(e) => !editData?.id ? setPlayerName(e.target.value) : setEditPlayer("name", e.target.value)}
            />
            <br /><br />
            <TextField
              id="outlined-basic-1"
              label="Имя"
              fullWidth
              variant="outlined"
              value={modalValueSurname}
              onChange={(e) => !editData?.id ? setPlayerSurname(e.target.value) : setEditPlayer("surname", e.target.value)}
            />
            <br /><br />


            <TextField
              id="outlined-basic-1"
              label="Forehand"
              fullWidth
              variant="outlined"
              value={modalValueForehand}
              onChange={(e) => !editData?.id ? setPlayerForehand(e.target.value) : setEditPlayer("forehand", e.target.value)}
            />

            <br /><br />
            <TextField
              id="outlined-basic-1"
              label="Backhand"
              fullWidth
              variant="outlined"
              value={modalValueBackHand}
              onChange={(e) => !editData?.id ? setPlayerBackhand(e.target.value) : setEditPlayer("backhand", e.target.value)}
            />

            <br /><br />
            <TextField
              id="outlined-basic-1"
              label="Основание"
              fullWidth
              variant="outlined"
              value={modalValueBlade}
              onChange={(e) => !editData?.id ? setPlayerBlade(e.target.value) : setEditPlayer("blade", e.target.value)}
            />
            <br /><br />
            <TextField
              id="outlined-basic-2"
              label="Рейтинг игрока"
              fullWidth
              variant="outlined"
              value={modalRating}
              onChange={(e) => !editData?.id ? checkPlayerRating(e.target.value) : setEditPlayer("rating", e.target.value)}
            />
            <br /><br />
            <TextField
              id="outlined-basic-2"
              label="Тренер"
              fullWidth
              variant="outlined"
              value={modalValueCoach}
              onChange={(e) => !editData?.id ? setPlayerCoach(e.target.value) : setEditPlayer("coach", e.target.value)}
            />
            <br /><br />
            <div className="player-hand-hvat">
              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Хват</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={modalValueHvat}
                  onChange={(e) => !editData?.id ? setPlayerHvat(e.target.value) : setEditPlayer("hvat", e.target.value)}
                >
                  <FormControlLabel value="euro" control={<Radio />} label="Европейская" />
                  <FormControlLabel value="asia" control={<Radio />} label="Азиатская/Перо" />
                </RadioGroup>
              </FormControl>

              <FormControl>
                <FormLabel id="demo-row-radio-buttons-group-label">Рабочая рука</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={modalValueHand}
                  onChange={(e) => !editData?.id ? setPlayerHand(e.target.value) : setEditPlayer("hand", e.target.value)}
                >
                  <FormControlLabel value="left" control={<Radio />} label="Левая рука" />
                  <FormControlLabel value="right" control={<Radio />} label="Правая рука" />
                </RadioGroup>
              </FormControl>
            </div>


          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} variant="contained">Отмена</Button>
            <Button onClick={() => editData?.id ? editPlayer() : addPlayer()} disabled={!modalValueName || !modalValueSurname || !modalRating}>{btnLabel}</Button>
          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onDeletePlayer = () => {


    setFetchDeletePlayer(true)
    const formData = new FormData()
    formData.append("id", editData.id)


    axiosFetch(DELETE_PLAYER, formData).then((res) => {
      cancelEditMode()
      setFetchDeletePlayer(false)
      setFetchPlayers(!fetchPlayers)

    })
  }

  const renderDeleteModal = () => {

    return (
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ minWidth: 500 }}>
          <DialogTitle >Удаляем игрока?</DialogTitle>
          <DialogContent >

          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            //disabled={disabled || fetchAddGame}
            //className="add-result-btn"
            >
              Отмена
            </Button>
            <Button
              onClick={() => onDeletePlayer()}
              variant="contained"
            //disabled={disabled || fetchEditGame}
            // className="add-result-btn"
            >
              Удалить
              {fetchDeletePlayer && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onShowDeleteData = (row) => {
    setEditData(row)
    setShowDeleteModal(true)
  }

  const renderActivity = ({ row }) => {
    return (
      <div className="action-icons">

        <Link to={`/player/${row.id}`}>
          <EyeIcon
            // onClick={(e) => showHistory(e, row)} 
            className="show-history"
          />
        </Link>
        {tcaToken && <>
          <PencilIcon
            onClick={() => onCellClick(row)}
            className="pencil-icon"
          />
          <TrashIcon
            onClick={() => onShowDeleteData(row)}
            className="trash-icon"
          />
        </>}

      </div>
    );
  };

  const columns = [
    // {
    //   field: 'id',
    //   headerName: '#',
    //   renderCell: (row) => {
    //     return renderActivity(row);
    //   },
    // },
    {
      field: 'name', headerName: 'Фамилия Имя', flex: 1,
      renderCell: ({ row }) => {
        return `${row.name} ${row.surname} `;
      },
    },
    { field: 'rating', headerName: 'Рейтинг', flex: 1 },
    {
      field: "activity",
      headerName: "Действия",
      cellClassName: "action-buttons",
      flex: 1,
      renderCell: (row) => {
        return renderActivity(row);
      },
    },
  ];

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      {tcaToken && <Button
        variant="contained"
        style={{ margin: "0 auto 20px", display: "block" }}
        onClick={handleClickOpen}
        disabled={isFetching}
      >
        Добавить игрока
      </Button>}
      <Grid container spacing={1} className="Players-container">
        <Grid item xs={12} style={{ textAlign: "center", minHeight: 200 }}>
          {isFetching ? <CircularProgress className="circular-progress" size={18} /> :
            <DataGrid
              rows={players}
              columns={columns}
              className="bg-white players"
              // style={{ maxWidth: 800, margin: "0 auto" }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 100 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
            />}
        </Grid>
      </Grid>

    </>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(Players)
);