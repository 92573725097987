import React, { useState, forwardRef, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import { DataGrid } from '@mui/x-data-grid';
import { PencilIcon } from "../../../../svg-icons/icon-pencil";
import { TrashIcon } from "../../../../svg-icons/icon-trash";
import { ADD_EVENT, GET_EVENTS, EDIT_EVENT, DELETE_EVENT } from "../../../../helpers/constants"
import axiosFetch from "../../../../helpers/axios"
import moment from "moment"
import CircularProgress from '@mui/material/CircularProgress';
import Grid from '@mui/material/Grid';
import CookieHelper from "../../../../helpers/cookie-helper";
// import { EyeIcon } from "../../../../helpers/icons/icon-eye"

import "./styles.scss"


const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function Events(props) {

  const [open, setOpen] = useState(false);
  const [events, setEvents] = useState([]);
  const [fetchEvents, setFetchEvents] = useState(false)
  const [fetchDeleteEvent, setFetchDeleteEvent] = useState(false)
  const [isFetching, setIsFetching] = useState(false)
  const [eventName, setEventName] = useState("Воскресный турнир");
  const [tcaToken, setTcaToken] = useState("");
  const [editData, setEditData] = useState({})
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    const isToken = CookieHelper("get", "tca-token");
    setTcaToken(isToken)
  }, [])

  useEffect(() => {
    setIsFetching(true)
    axiosFetch(GET_EVENTS).then(res => {
      setEvents(res)
      setIsFetching(false)
    })

  }, [fetchEvents])

  const addEvent = () => {
    const formData = new FormData()
    formData.append("name", eventName)
    formData.append("date", moment().format("DD/MM/YYYY"))

    axiosFetch(ADD_EVENT, formData).then(res => {
      if (res === "OK") {
        setFetchEvents(!fetchEvents)
        setEventName("");
        setOpen(false);
      }
    })
  }

  const editEvent = () => {
    const formData = new FormData()
    formData.append("name", editData.name)
    formData.append("id", editData.id)

    axiosFetch(EDIT_EVENT, formData).then(res => {
      if (res === "OK") {
        setFetchEvents(!fetchEvents)
        setEventName("");
        setOpen(false);
      }
    })
  }


  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onCellClick = (row) => {
    setEditData(row);
    handleClickOpen()
  };

  const setEditEvent = (name) => {
    setEditData({
      ...editData,
      name
    })
  }

  const cancelEditMode = () => {
    setEditData({})
    setShowDeleteModal(false)
  }

  const renderModal = () => {
    const modalValue = editData?.id ? editData.name : eventName
    const btnLabel = editData?.id ? "Редактировать" : "Добавить"

    return (
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"

      >
        <div className="add-event-container" style={{ minWidth: 500 }}>
          <DialogTitle>Добавление события</DialogTitle>
          <DialogContent>
            <TextField
              id="outlined-basic"
              label="Наименование события"
              fullWidth
              variant="outlined"
              value={modalValue}
              onChange={(e) => !editData?.id ? setEventName(e.target.value) : setEditEvent(e.target.value)}
            />
          </DialogContent>
          <DialogActions>

            <Button onClick={handleClose} variant="contained">Отмена</Button>
            <Button onClick={() => editData?.id ? editEvent() : addEvent()} disabled={!modalValue}>{btnLabel}</Button>
          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onDeleteResult = () => {


    setFetchDeleteEvent(true)
    const formData = new FormData()
    formData.append("id", editData.id)


    axiosFetch(DELETE_EVENT, formData).then((res) => {
      cancelEditMode()
      setFetchDeleteEvent(false)
      setFetchEvents(!fetchEvents)

    })
  }

  const renderDeleteModal = () => {

    return (
      <Dialog
        open={showDeleteModal}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => cancelEditMode()}
        aria-describedby="alert-dialog-slide-description"
      >
        <div style={{ minWidth: 500 }}>
          <DialogTitle >Удаляем событие?</DialogTitle>
          <DialogContent >

          </DialogContent>
          <DialogActions >
            <Button
              onClick={() => cancelEditMode()}
              variant="contained"
            //disabled={disabled || fetchAddGame}
            //className="add-result-btn"
            >
              Отмена
            </Button>
            <Button
              onClick={() => onDeleteResult()}
              variant="contained"
            //disabled={disabled || fetchEditGame}
            // className="add-result-btn"
            >
              Удалить
              {fetchDeleteEvent && <CircularProgress className="circular-progress" size={18} />}
            </Button>


          </DialogActions>
        </div>

      </Dialog>
    )
  }

  const onShowDeleteData = (row) => {
    setEditData(row)
    setShowDeleteModal(true)
  }

  const renderActivity = ({ row }) => {
    return (
      <div className="action-icons">
        <PencilIcon
          onClick={() => onCellClick(row)}
          className="pencil-icon"
        />
        <TrashIcon
          onClick={() => onShowDeleteData(row)}
          className="trash-icon"
        />
      </div>
    );
  };

  const columnsAdmin = [
    // {
    //   field: 'id',
    //   headerName: '#',
    //   renderCell: (row) => {
    //     return renderActivity(row);
    //   },
    // },
    { field: 'name', headerName: 'Наименование события', flex: 1 },
    { field: 'date', headerName: 'Дата', flex: 1 },
    {
      field: "activity",
      headerName: tcaToken ? "Действия" : "Просмотр",
      cellClassName: "action-buttons",
      flex: 1,
      renderCell: (row) => {
        return renderActivity(row);
      },
    },
  ];
  const columns = [
    { field: 'name', headerName: 'Наименование события', flex: 1 },
    { field: 'date', headerName: 'Дата', flex: 1 },
  ];

  return (
    <>
      {renderModal()}
      {renderDeleteModal()}
      {tcaToken && <Button variant="contained" style={{ margin: "0 auto 20px", display: "block" }} onClick={handleClickOpen}>
        Добавить событие
      </Button>}
      <Grid container spacing={1} className="events-container">
        <Grid item xs={12}>
          {isFetching ? <CircularProgress className="circular-progress" size={18} /> :
            <DataGrid
              rows={events}
              columns={tcaToken ? columnsAdmin : columns}
              className="bg-white events"
              style={{ maxWidth: tcaToken ? 800 : 500, margin: "0 auto" }}
              initialState={{
                pagination: {
                  paginationModel: { page: 0, pageSize: 10 },
                },
              }}
              pageSizeOptions={[5, 10]}
              checkboxSelection={false}
            />}
        </Grid>
      </Grid>

    </>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(Events)
);