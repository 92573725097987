import React from "react";
import CheckDelta from "../homepage/show-match-delta"
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function CheckDeltaPage() {
    return (
        <AppMainLayout>
            <div className="row">
                <div className="col-md-12">
                    <CheckDelta />
                </div>
            </div>
        </AppMainLayout>
    );
}


export default CheckDeltaPage