import React from "react";
import AddResult from "../homepage/add-result"
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function AddResultPage() {
    return (
        <AppMainLayout>
            <div className="row">
                <div className="col-md-12">
                    <AddResult />
                </div>
            </div>
        </AppMainLayout>
    );
}


export default AddResultPage