import {
    GLOBAL_SETTINGS,
} from "../actions/Actions";

const initialState = {
    usd: 0,
    euro: 0,
    minSummOrder: 0,
    freeDeliveryFrom: 0,
    availableSite: 1,
    deliveryPrice: 0,
    adminEmail: "",
    adminPhone: "",
    notification: 0,
    titleRu: "",
    titleRo: "",
    descriptionRu: "",
    descriptionRo: "",
};
 
export default function GlobalSettingsReducer(state = initialState, action) {
    switch (action.type) {

        case GLOBAL_SETTINGS:
            const { usd, 
                euro, 
                minSummOrder, 
                freeDeliveryFrom, 
                deliveryPrice, 
                adminEmail, 
                adminPhone, 
                availableSite, 
                titleRu,
                titleRo,
                descriptionRu,
                descriptionRo,
                notification
            } = action.payload

            return Object.assign({}, {
                ...state,
                usd,
                euro,
                minSummOrder,
                freeDeliveryFrom,
                deliveryPrice,
                adminEmail,
                adminPhone,
                availableSite: Number(availableSite),
                notification: Number(notification),
                titleRu,
                titleRo,
                descriptionRu,
                descriptionRo,
            });

        default:
            return state;
    }
}
