import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";
import Header from "../../components/header";
import Footer from "../../components/footer";

import "./styles.scss"


function AppMainLayout({
    children
}) {
    return (
        <div>
            <div className="common-bg-container" />
            <Header />
            <div className="container app-main-layout">
                {children}
            </div>
            <Footer />
        </div>
    );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
    compose(
        connect(mapStateToProps, {}),
    )(AppMainLayout)
);