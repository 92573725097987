import React from 'react';

export const TrashIcon = ({ onClick, className }) => {
    return (
        <svg onClick={onClick} className={className} width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="52" height="52" transform="translate(0.988281)" fill="white" />
            <rect width="52" height="52" transform="translate(0.988281)" fill="white" />
            <path d="M20.9883 33C20.9883 34.1 21.8883 35 22.9883 35H30.9883C32.0883 35 32.9883 34.1 32.9883 33V23C32.9883 21.9 32.0883 21 30.9883 21H22.9883C21.8883 21 20.9883 21.9 20.9883 23V33ZM32.9883 18H30.4883L29.7783 17.29C29.5983 17.11 29.3383 17 29.0783 17H24.8983C24.6383 17 24.3783 17.11 24.1983 17.29L23.4883 18H20.9883C20.4383 18 19.9883 18.45 19.9883 19C19.9883 19.55 20.4383 20 20.9883 20H32.9883C33.5383 20 33.9883 19.55 33.9883 19C33.9883 18.45 33.5383 18 32.9883 18Z" fill="#A1ABB2" />
        </svg>

    );
};