import React from "react";
import "./styles.scss"


function Footer() {

    return (
        <div>
            <div class="footer">
                <div class="container">
                    <div class="col-md-4">
                        <div class="widget">
                            <h2 class="widget-title">Администрация</h2>
                            <ul class="widget-contact">
                                <li class="widget-contact-phone">111-111-111</li>
                                <li class="widget-contact-phone">222-222-222</li>
                                <li class="widget-contact-mail"><a href="mailto:mail@mail.com">mail@mail.com</a></li>
                                <li class="widget-contact-address">Россия<br />Москва 15.3<br />ул. Пушкина 23</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="widget">
                            <h2 class="widget-title">Последнее на сайте</h2>
                            <ul class="widget-latest-posts">
                                <li>
                                    <span class="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/travel/post-1.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span class="widget-latest-posts-time">Дата : 31 авг</span>
                                </li>
                                <li>
                                    <span class="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/technology/post-2.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span class="widget-latest-posts-time">Дата : 31 августа</span>
                                </li>
                                <li>
                                    <span class="widget-latest-posts-cover">
                                        <a href="#">
                                            <img src="images/photos/sport/post-3.jpg" alt="post image" />
                                        </a>
                                    </span>
                                    <h4><a href="#">Заголовок публикуемой на сайте новости</a></h4>
                                    <span class="widget-latest-posts-time">Дата : 31 августа</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-md-2">
                        <div class="widget">
                            <h2 class="widget-title">Мы в соц. сетях</h2>
                            <ul class="widget-follow-us">
                                <li><a class="widget-follow-us-facebook" href="http://html6.com.ru/">Facebook</a></li>
                                <li><a class="widget-follow-us-twitter" href="http://html6.com.ru/">Twitter</a></li>
                                <li><a class="widget-follow-us-dribbble" href="http://html6.com.ru/">Dribbble</a></li>
                                <li><a class="widget-follow-us-google" href="http://html6.com.ru/">Google+</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="footer-copyright">
                    <div>
                        2024 | TCA Rating.
                    </div>
                    <div>
                        Статистика собирается с <b>17.03.2024</b>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer