import React from "react";
import Events from "../homepage/events"
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function EventsPage() {
    return (
        <AppMainLayout>
            <div className="row">
                <div className="col-md-12">
                    <Events />
                </div>
            </div>
        </AppMainLayout>
    );
}


export default EventsPage