export const APP_TITLE = "Comfort Premium | ";
export const SITE_URL = "http://localhost:3000/";
export const API_URL = 'https://tca.ssv-design.com/Tca';
export const BASE_URL = 'https://tca.ssv-design.com/';


export const NO_IMAGE_URL = BASE_URL + 'uploads/no-image.png';

export const ADD_GAME_RESULT = API_URL + "/addGameResult" 
export const GET_EVENT_GAMES = API_URL + "/getEventGames"  
export const GET_PLAYERS_LIST = API_URL + "/getPlayersList"  
export const EDIT_GAME_RESULT = API_URL + "/editGameResult"  
export const DELETE_GAME_RESULT = API_URL + "/deleteGameResult"  
 
export const ADD_EVENT = API_URL + "/addEvent"  
export const GET_EVENTS = API_URL + "/getEvents"  
export const EDIT_EVENT = API_URL + "/editEvent"  
export const DELETE_EVENT = API_URL + "/deleteEvent"  


export const ADD_PLAYER = API_URL + "/addPlayer"  
export const GET_PLAYERS = API_URL + "/getPlayers"  
export const EDIT_PLAYER = API_URL + "/editPlayer"  
export const DELETE_PLAYER = API_URL + "/deletePlayer"  

export const CALC_EVENT_RATING = API_URL + "/calcEventRating"   
export const GET_EVENT_RATING = API_URL + "/getEventRating" 
export const GET_PLAYER_INFO = API_URL + "/getPlayerInfo" 

export const GET_PLAYER_GAMES = API_URL + "/getPlayerGames" 
export const GET_PLAYER_CHART_STATISTIC = API_URL + "/getPlayerChartStatistic"  
export const GET_PLAYERS_RATING_FOR_PAGE = API_URL + "/getPlayersRatingForPagePlayerDetails"   
