import {
    LOGIN,
    LOGIN_FAIL,
    LOGIN_SUCCESS,
} from "../actions/Actions";

const initialState = {
    isFetching: false,
    loginData: {
        login: ""
    },
};
 
export default function LoginReducer(state = initialState, action) {
    switch (action.type) {
        case LOGIN:
            return Object.assign({}, {
                ...state,
                isFetching: true,
            });
        case LOGIN_SUCCESS:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                loginData: action.payload,
            });
        case LOGIN_FAIL:
            return Object.assign({}, {
                ...state,
                isFetching: false,
                loginData: {},
            });
        default:
            return state;
    }
}
