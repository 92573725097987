import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { compose } from "redux";

import "./styles.scss"


function HomePage() {

  useEffect(() => {
   
  }, [])


  return ( 
    <div>
     div
    </div>
  );
}

const mapStateToProps = (state) => ({

});

export default withRouter(
  compose(
    connect(mapStateToProps, {}),
  )(HomePage)
);