import React from 'react';

export const PencilIcon = ({ onClick, className }) => {
    return (
        <svg onClick={onClick} className={className} width="53" height="52" viewBox="0 0 53 52" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="52" height="52" transform="translate(0.988281)" fill="white" />
            <rect width="52" height="52" transform="translate(0.988281)" fill="white" />
            <path d="M18.9883 30.8538V33.5556C18.9883 33.8045 19.1838 34 19.4327 34H22.1345C22.2501 34 22.3656 33.9556 22.4456 33.8667L32.1509 24.1703L28.818 20.8374L19.1216 30.5338C19.0327 30.6227 18.9883 30.7293 18.9883 30.8538ZM34.7283 21.5928C35.0749 21.2462 35.0749 20.6863 34.7283 20.3397L32.6486 18.26C32.302 17.9133 31.7421 17.9133 31.3954 18.26L29.769 19.8864L33.1019 23.2193L34.7283 21.5928Z" fill="#A1ABB2" />
        </svg>
    );
};