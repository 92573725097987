import React from "react";
import Players from "../homepage/players"
import AppMainLayout from "../../../layouts/app-main-layout"

import "./styles.scss"

function PlayersPage() {
    return (
        <AppMainLayout>
            <div className="row">
                <div className="col-md-12">
                    <Players />
                </div>
            </div>
        </AppMainLayout>
    );
}


export default PlayersPage