import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import CookieHelper from "../../helpers/cookie-helper";
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import topcup from "../../images/topcup.jpg"
import "./styles.scss"
import classNames from "classnames"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


function Header() {
    const [showModal, setShowModal] = useState(false);
    const [tcaToken, setTcaToken] = useState(null);
    const [password, setPassword] = useState("");
    const [login, setLogin] = useState("");
    const [error, setError] = useState("");
    const [showExitModal, setShowExitModal] = useState(false);
    const [isShowMobileMenu, setIsShowMobileMenu] = useState(false);

    useEffect(() => {
        const isToken = CookieHelper("get", "tca-token");
        setTcaToken(isToken)
    }, [])

    const setToken = () => {
        var token = "";
        var possible = "AQWERTYUIOPLKJHGFDSAZXCVBNM!@#$%^&*()_+-=abcdefghijklmnopqrstuvwxyz1234567890";

        for (var i = 0; i < 50; i++)
            token += possible.charAt(Math.floor(Math.random() * possible.length));

        return token;
    }

    const onAdminMode = (action = "login") => {
        if (action === "login" && Number(password) !== 4142135) {
            setError("Пароль неверный...")
        } else if (action === "logout") {
            CookieHelper("remove", "tca-token");
            window.location.reload();
        } else {
            CookieHelper("set", "tca-token", setToken());
            window.location.reload();
        }
    }

    const renderAuthModal = () => {

        return (
            <Dialog
                open={showModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowModal(false)}
                aria-describedby="alert-dialog-slide-description"
                  className="auth-exit-modal"
            >
                <div >
                    <DialogTitle >Авторизация</DialogTitle>
                    <br />
                    <DialogContent >
                        <DialogContentText id="alert-dialog-slide-description">
                            <Grid container >
                                <Grid item xs={12} className="auth-field">
                                    <TextField
                                        id="outlined-basic"
                                        className="set-score"
                                        label="Логин"
                                        variant="outlined"
                                        value={login}
                                        type="login"
                                        onChange={(e) => { setLogin(e.target.value); setError("") }}
                                    />
                                </Grid>
                                <Grid item xs={12} className="auth-field">
                                    <TextField
                                        id="outlined-basic"
                                        className="set-score"
                                        label="Пароль"
                                        variant="outlined"
                                        value={password}
                                        type="password"
                                        onChange={(e) => { setPassword(e.target.value); setError("") }}
                                    />
                                </Grid>

                            </Grid>
                            {error && <div className="error">{error}</div>}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions >
                        <Button
                            onClick={() => setShowModal(false)}
                            variant="contained"
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={() => onAdminMode()}
                            variant="contained"
                            disabled={!password}
                        >
                            Войти
                        </Button>
                    </DialogActions>
                </div>
            </Dialog>
        )
    }

    const renderExitModal = () => {

        return (
            <Dialog
                open={showExitModal}
                TransitionComponent={Transition}
                keepMounted
                onClose={() => setShowModal(false)}
                aria-describedby="alert-dialog-slide-description"
                className="auth-exit-modal"
            >
                <div>
                    <DialogTitle >Выходим?</DialogTitle>
                    <DialogActions >
                        <Button
                            onClick={() => setShowExitModal(false)}
                            variant="contained"
                        >
                            Отмена
                        </Button>
                        <Button
                            onClick={() => onAdminMode("logout")}
                            variant="contained"
                        >
                            Выйти
                        </Button>


                    </DialogActions>
                </div>

            </Dialog>
        )
    }



    const checkForSelectedMenu = (prefix, isHomePage = false) => {
        if (isHomePage) {
            const currentUrl = window.location.href

            const noInCheckDelta = currentUrl.includes("/check-delta")
            const noInAllEvents = currentUrl.includes("/events")
            const noInAllPlayers = currentUrl.includes("/players") || currentUrl.includes("/player")
            const noInAddResult = currentUrl.includes("/add-result")

            return !noInCheckDelta && !noInAllEvents && !noInAllPlayers && !noInAddResult
        } else {
            const currentUrl = window.location.href;
            return currentUrl.includes(prefix)
        }

    }

    return (
        <div className="header">
            {renderAuthModal()}
            {renderExitModal()}
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="logo">

                            <Link to="/"><img src={topcup} alt="top-cup-logo" /></Link>
                            <Link to="/"><span className="site-text-color logo-span">Top</span> Cup rating</Link>
                        </div>
                    </div>


                </div>
            </div>

            <div className="menu site-bg-color">
                <div className="container">
                    <div className="responsive-menu" onClick={() => setIsShowMobileMenu(!isShowMobileMenu)}>Меню</div>
                    <ul
                        className={classNames({
                            'responsive-menu-active': true,
                            'show-mobile-menu': isShowMobileMenu
                            //`${cName}`: true
                        })}
                    >

                        <li className={` ${checkForSelectedMenu("/", true) && "current-page"}`}><Link to="/">Главная</Link></li>
                        <li className={` ${checkForSelectedMenu("/check-delta") && "current-page"}`}><Link to="/check-delta">Проверить дельту</Link> </li>
                        <li className={` ${checkForSelectedMenu("/events") && "current-page"}`}><Link to="/events">События</Link></li>
                        <li className={` ${checkForSelectedMenu("/players") && "current-page"}`}><Link to="/players">Все игроки</Link></li>

                        {tcaToken && <li className={` ${checkForSelectedMenu("/add-result") && "current-page"}`}><Link to="/add-result">Добавление результата</Link></li>}
                        <li >

                            {!tcaToken ?
                                <Link to="#" onClick={() => setShowModal(true)} >Авторизация</Link> :
                                <>
                                    <Link to="#"  >
                                        <div className="exit-label" onClick={() => setShowExitModal(true)}>(выйти)</div></Link>

                                </>
                            }
                        </li>
                        {/* <li>
                            <div style={{ display: "flex" }}>

                                <AdminPanelSettingsIcon
                                    onClick={() => setShowModal(true)}
                                    className={`log-in ${tcaToken && "admin-mode-icon"}`}
                                />
                                {tcaToken &&
                                    <div className="exit-label" onClick={() => setShowExitModal(true)}>(выйти)</div>}
                            </div>

                        </li> */}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default Header